<template>
  <div class="container">
    <template v-if="!isChangePassword">
      <h2 class="title">编辑资料</h2>
      <div class="section">
        <h3 class="section-title">个人头像</h3>
        <div class="section-img">
          <img class="user-avatar"
            :src="formData.avatarUrl && $store.state.assetsUrl + formData.avatarUrl || DefaultAvatarUrl" alt="">
          <el-upload class="upload-demo" :action="baseUrl + '/uploader/uploadFile'" :data="{ type: 'logo' }"
            :headers="{ [tokenKeyName]: token, system }" :on-success="uploadSuccess" :before-upload="beforeUpload"
            :show-file-list="false">
            <el-button class="change-btn">更换头像</el-button>
          </el-upload>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">个人信息</h3>
        <div class="section-item">
          <span class="section-item-label">用户名</span>
          <span class="section-item-value">{{ userInfo.account }}</span>
        </div>
        <div class="section-item">
          <span class="section-item-label">密码</span>
          <span class="section-item-value">****** <el-button type="text" size="mini"
              @click="isChangePassword = true">修改密码</el-button></span>
        </div>
        <div class="section-item">
          <span class="section-item-label">修改昵称</span>
          <span class="section-item-value"><el-input class="nickname-input" size="mini" v-model="formData.nickName"
              :maxlength="20" :show-word-limit="true"></el-input></span>
        </div>
      </div>
      <div class="btns">
        <el-button class="btn cancel" size="mini" @click="$router.back()">返回</el-button>
        <el-button class="btn submit" size="mini" type="primary" @click="submitChangeBasic">修改</el-button>
      </div>
    </template>
    <template v-else>
      <h2 class="title">修改密码</h2>
      <div class="section">
        <h3 class="section-title">密码信息</h3>
        <div class="section-item">
          <span class="section-item-label">旧密码</span>
          <span class="section-item-value">
            <el-input style="width: 250px" type="password" size="mini" autocomplete="new-password"
              v-model="passwordChangeForm.oldPassword"></el-input>
          </span>
        </div>
        <div class="section-item">
          <span class="section-item-label">新密码</span>
          <span class="section-item-value">
            <el-input style="width: 250px" type="password" size="mini" autocomplete="new-password"
              v-model="passwordChangeForm.newPassword"></el-input>
          </span>
        </div>
        <div class="section-item">
          <span class="section-item-label">确认密码</span>
          <span class="section-item-value">
            <el-input style="width: 250px" type="password" size="mini" autocomplete="new-password"
              v-model="passwordChangeForm.repeatPassword"></el-input>
          </span>
        </div>
      </div>
      <div class="btns">
        <el-button class="btn cancel" size="mini" @click="isChangePassword = false">返回基本信息</el-button>
        <el-button class="btn submit" size="mini" type="primary" @click="submitChangePassword">修改</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import { UserBasicFormData, PasswordChangeFormData } from "../../assets/data/formbuilder";
import { setOptions } from "../../utils";
import DefaultAvatarUrl from "../../assets/imgs/default_avatar.png"
import { editUserBasicInfo, changePassword } from "../../api/users"
import { validPassword } from "../../utils/validate";
import { system, tokenKeyName } from "../../setting"
export default {
  name: "Personal",
  data() {
    return {
      formData: new UserBasicFormData().build(),
      DefaultAvatarUrl,
      isChangePassword: false,
      passwordChangeForm: new PasswordChangeFormData().build(),
      tokenKeyName,
      system
    };
  },
  computed: {
    avatarList() {
      return setOptions("avatar_list");
    },
    token() {
      return this.$store.state.token;
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.formData = new UserBasicFormData(this.userInfo).build();
  },
  methods: {
    uploadSuccess(res, file) {
      if (res.code != 200) return this.$message.error(res.message);
      this.formData.avatarUrl = res.data;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    /**
     * @name 修改基本信息
     */
    submitChangeBasic() {
      editUserBasicInfo(this.formData).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);

        this.$message.success("修改成功");
        this.$store.dispatch("updateUserBasic", {
          ...this.formData,
        });
      });
    },
    /**
     * @name 修改密码
     */
    submitChangePassword() {
      if (!this.passwordChangeForm.repeatPassword || !this.passwordChangeForm.newPassword || !this.passwordChangeForm.oldPassword) return this.$message.error("请将密码填写完整");
      if (this.passwordChangeForm.newPassword != this.passwordChangeForm.repeatPassword) return this.$message.error("两次密码输入的不一致");
      if (!validPassword(this.passwordChangeForm.newPassword)) return this.$message.error("密码由8～16位数字、字母组成，请检查格式")

      changePassword(this.passwordChangeForm).then(res => {
        if (res.code != 200) return this.$message.error(res.message);

        this.$message.success("修改成功");
        this.passwordChangeForm = new PasswordChangeFormData().build();
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 438px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 20px;
  padding-bottom: 33px;
  box-sizing: border-box;

  .title {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    padding: 10px 20px;
    background-color: #232529;
    border-radius: 4px;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 30px;
    border-radius: 4px;
    background-color: #232529;
  }

  .section-title {
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    padding: 10px 20px;
    border-bottom: 1px solid #000;
  }

  .section-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    color: #fff;

    .user-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 40px;
    }

    .change-btn {
      padding: 0 30px;
      line-height: 24px;
      height: 24px;
    }
  }

  .section-item {

    padding: 20px 20px 0;
    color: #fff;
    font-size: 14px;

    .section-item-label {
      margin-right: 10px;
      display: inline-block;
      width: 80px;
    }

    .section-item-value {
      display: inline-block;
      width: calc(100% - 90px);
    }

    .nickname-input {
      width: 300px;
    }

    &:nth-last-of-type(1) {
      padding-bottom: 20px;
    }
  }

  .btns {
    .btn {
      width: 180px;
      padding: 0;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
